import { Controller } from "@hotwired/stimulus";
import { turbo_fetch } from "../helpers";

export default class extends Controller {

  connect() {
    window.removeEventListener('turbo:load', this.hideSpinner());
    window.removeEventListener('pageshow', this.hideSpinner());
  }

  setToDate(event) {
    let from = new Date(event.target.value);
    let tmp = new Date(from);
    let to = document.getElementById("to");
    let min = this.check_season(from);

    tmp.setDate(from.getDate() + min);
    tmp = tmp.toISOString().split("T")[0];
    to.setAttribute("min", tmp);
    if (!to.value || to.value < tmp) to.value = tmp;
  }

  check_season(date) {
    let summer_start = document.getElementById("season_from");
    let summer_end = document.getElementById("season_to");
    let min = 2;

    if (summer_start && summer_end) {
      summer_start = new Date(summer_start.value);
      summer_end = new Date(summer_end.value);
      if (date >= summer_start && date <= summer_end) min = 0;
    }
    return min;
  }

  toggle(event) {
    let row;
    let path = event.path || (event.composedPath && event.composedPath());
    path.forEach((el) => {
      if (el.dataset && el.dataset.row) {
        row = el.dataset.row;
      }
    });
    if ( row ) {
      let down = document.getElementById(`chevron-down-${row}`);
      let up = document.getElementById(`chevron-up-${row}`);
      let table = document.getElementById(`table-${row}`);
      down.classList.toggle('hidden');
      up.classList.toggle('hidden');
      table.classList.toggle('hidden');
    }
  }

  showSpinner() {
    const spinner = document.getElementById('spinner');
    if (spinner) spinner.classList.remove('hidden');
  }

  hideSpinner() {
    const spinner = document.getElementById('spinner');
    if (spinner) spinner.classList.add('hidden');
  };

  filterBookings(event) {
    const spinner = document.getElementById('bookings_spinner')
    if (spinner) spinner.classList.remove('hidden')
    const url = event.target.dataset.url;
    const status = event.target.value;
    turbo_fetch(`${url}/state/${status}`)
  }

}